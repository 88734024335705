import { memo } from 'react';

import styled from '@emotion/styled';
import { SnackbarProvider } from 'notistack';
import { useAppSelector } from 'redux/hooks';

import { CustomSnackbar } from './Snackbar';

type SnackbarCustomProviderProps = {
  children: JSX.Element | JSX.Element[];
};

export const SnackbarCustomProvider = memo<SnackbarCustomProviderProps>(
  ({ children }) => {
    const isBannerMounted = useAppSelector(state => state.subscription.isShown);

    return (
      <SnackbarProviderWrapper>
        <SnackbarProvider
          dense
          maxSnack={5}
          autoHideDuration={7000}
          anchorOrigin={{
            vertical: 'bottom',
            horizontal: 'center',
          }}
          Components={{
            success: CustomSnackbar,
            error: CustomSnackbar,
            warning: CustomSnackbar,
          }}
          classes={
            isBannerMounted
              ? {
                  containerAnchorOriginBottomCenter:
                    'containerAnchorOriginBottomCenter',
                }
              : {}
          }
        >
          {children}
        </SnackbarProvider>
      </SnackbarProviderWrapper>
    );
  }
);

const SnackbarProviderWrapper = styled.div`
  .containerAnchorOriginBottomCenter {
    transform: translateY(-64px) translateX(-50%);
  }
`;
