import { ErrorsType } from 'redux/types';

export type User = {
  user_id: string;
  organization_id: string;
  workspace_id: string;
};

export type StateModel = {
  user: User;
  isLoggedIn: boolean;
  error: Partial<ErrorsType>;
};

export type Credentials = {
  email: string;
  password: string;
  type: LoginType;
  token: string;
  firstName: string;
  lastName: string;
  organizationName: string;
  invitationToken: string;
  aff_id: string;
};

export enum LoginType {
  BASIC = 'basic',
  GOOGLE = 'google',
  MS = 'microsoft',
}

export type UserInfo = {
  email: string;
  password: string;
  firstName: string;
  lastName: string;
  avatarPath: string;
  id: string;
};

export type recoveryPasswordBody = {
  token: string;
  password: string;
};

export type VerifyTokenBody = {
  token: string;
  flow: string;
};

export type TokenDecoded = {
  id: string;
  email: string;
  organization: string;
};
