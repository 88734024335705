import {
  Dispatch,
  forwardRef,
  SetStateAction,
  useEffect,
  useMemo,
  useState,
} from 'react';

import Box from '@mui/material/Box';
import MenuList from '@mui/material/MenuList';
import Typography from '@mui/material/Typography';
import { styled } from '@mui/system';
import { useTranslation } from 'react-i18next';

import { CheckableMenuItem } from './CheckableMenuItem';
import { FilterPopper, PopoverHandle } from './FilterPopper';
import { FilterSearchField } from './FilterSearchField';

type AccountFilterProps = {
  items: string[];
  setItems: Dispatch<SetStateAction<string[]>>;
  list: { id: string; name: string }[];
  saturate?: boolean;
};

export const BaseFilterComponent = forwardRef<
  PopoverHandle,
  AccountFilterProps
>(({ items, setItems, list, saturate = true }, popperRef) => {
  const { t } = useTranslation();

  const [searchValue, setSearchValue] = useState('');

  const filteredList = useMemo(
    () =>
      list?.filter(
        item =>
          item.name?.toLowerCase()?.includes(searchValue.toLowerCase()) ||
          item.id?.toLowerCase()?.includes(searchValue.toLowerCase())
      ),
    [list, searchValue]
  );

  const handleAccountChange = (id: string) => {
    if (items.includes(id)) {
      setItems(prev => prev?.filter(itemId => itemId !== id));
    } else {
      setItems(prev => [...prev, id]);
    }
  };

  const onOnlyClick = (id: string) => {
    setItems([id]);
  };

  const isAllSelected = items.length === list?.length;

  const handleSelectAll = () => {
    if (isAllSelected) {
      setItems([]);
    } else {
      setItems(list?.map(item => item.id) || []);
    }
  };

  useEffect(() => {
    if (list && !items?.length && saturate) {
      setItems(list?.map(item => item.id) || []);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [list, saturate, setItems]);

  return (
    <FilterPopper ref={popperRef} placement="bottom-start">
      <Box>
        <FilterSearchField value={searchValue} setValue={setSearchValue} />
        <StyledMenuList>
          {filteredList?.map(({ id, name }) => (
            <CheckableMenuItem
              key={id}
              onChange={() => handleAccountChange(id)}
              checked={items.includes(id)}
              label={`${name} (${id})`}
              onOnlyClick={() => onOnlyClick(id)}
            />
          ))}
        </StyledMenuList>
        <TextButton onClick={handleSelectAll}>
          {isAllSelected ? t('buttons.unselect_all') : t('buttons.select_all')}
        </TextButton>
      </Box>
    </FilterPopper>
  );
});

const StyledMenuList = styled(MenuList)({
  overflowY: 'auto',
  maxHeight: 250,
});

const TextButton = styled(Typography)(() => ({
  margin: '11px auto 20px',
  fontWeight: 600,
  textAlign: 'center',
  cursor: 'pointer',
}));
