import { FC } from 'react';

import Button from '@mui/material/Button';
import Checkbox from '@mui/material/Checkbox';
import FormControlLabel from '@mui/material/FormControlLabel';
import Typography from '@mui/material/Typography';
import CheckedIcon from 'assets/svg/tableCheckedBox.svg?react';
import Icon from 'assets/svg/tableUncheckedBox.svg?react';
import { useTranslation } from 'react-i18next';

import { StyledMenuItem } from './StyledMenuItem';

type CheckableMenuItemProps = {
  checked: boolean;
  label: string;
  onChange: () => void;
  onOnlyClick: () => void;
};

export const CheckableMenuItem: FC<CheckableMenuItemProps> = ({
  checked,
  label,
  onChange,
  onOnlyClick,
}) => {
  const { t } = useTranslation();

  return (
    <StyledMenuItem>
      <FormControlLabel
        control={
          <Checkbox
            onChange={onChange}
            checked={checked}
            checkedIcon={<CheckedIcon />}
            icon={<Icon />}
            disableRipple
            sx={{ padding: 0 }}
          />
        }
        label={<Typography ml="10px">{label}</Typography>}
      />
      <Button variant="outlined" disableRipple onClick={onOnlyClick}>
        {t('buttons.only')}
      </Button>
    </StyledMenuItem>
  );
};
