import { EPlatforms } from 'platformsConstants';

export const DEFAULT_TEMPLATE_ID = 'Default template ID';
export const DEFAULT_TEMPLATE_NAME = 'New tracking template';

export type GetArgsType = {
  platform: EPlatforms;
  organization_id: string;
};

export type PostArgsType = {
  organization_id: string;
  body: Partial<PostContentType>;
};

export type DeleteArgsType = {
  organization_id: string;
  platform: EPlatforms;
  templatesId: string[];
};

export type GetKeysType = {
  platform: EPlatforms;
  organization_id: string;
};

export type PostContentType = {
  templateName: string;
  adAccounts: string[];
  urlTemplate: TemplateType;
  templateId: string | null;
  platformType: EPlatforms;
};

export type ContentType = {
  name: string;
  adAccounts: string[];
  platformType: EPlatforms;
  urlTemplate: TemplateType;
  id: string;
  createdAt: string;
  updatedAt: string;
  createdBy:
    | {
        _id: string;
        firstName: string;
        lastName: string;
      }
    | string;
};

export type TemplateType = {
  [key: string]: ParamType;
};

export type ParamType = {
  value: string;
  isDynamic: boolean;
  toBeDeleted?: boolean;
  _id?: string;
};

export type ListDataType = [string, ParamType];

export type TemplateStateType = {
  [key in EPlatforms]: {
    [key: string]: {
      name: string;
      urlTemplate: ListDataType[];
    };
  };
};

export type OptionType = {
  inputValue: string;
  title: string;
};

export type DynamicValue = {
  value: string;
  description: string;
  example: string;
  selfParam: boolean;
};

export type EditTemplatePayload = {
  platform: EPlatforms;
  id: string;
};

export type SetParamNamePayload = EditTemplatePayload & {
  oldValue: string;
  newValue: string;
};

export type SetParamValuePayload = EditTemplatePayload & {
  name: string;
  value: string;
  isDynamic: boolean;
};

export type DeleteParamPayload = EditTemplatePayload & {
  name: string;
};

export type AddHubSpotParamsPayload = EditTemplatePayload & {
  params: ListDataType[];
};

export enum EPopupShown {
  ORGANIZATION = 'organization',
  PUBLISH = 'publish',
  DISCARD = 'discard',
}

export type TemplateStateProp = {
  name: string;
  urlTemplate: ListDataType[];
};

export type NewTemplateToEdit = EditTemplatePayload & {
  name: string;
  urlTemplate: TemplateType;
};

export type DefaultTemplateContent = {
  urlTemplate: TemplateType;
  platform: EPlatforms;
};
