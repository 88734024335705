import { forwardRef } from 'react';

import IconButton from '@mui/material/IconButton';
import AlertSuccessIcon from 'assets/svg/alert_success.svg?react';
import CloseIcon from 'assets/svg/closeSnackbar.svg?react';
import AlertErrorIcon from 'assets/svg/error_alert.svg?react';
import { closeSnackbar, SnackbarAction } from 'notistack';

import { StyledMuiAlert } from './StyledMuiAlert';

type CustomSnackbarProps = {
  variant: 'success' | 'error' | 'warning';
  message: string;
  id: string;
  action?: SnackbarAction;
};

export const CustomSnackbar = forwardRef<HTMLDivElement, CustomSnackbarProps>(
  ({ variant, message, id, action }, ref) => {
    return (
      <StyledMuiAlert
        onClick={() => closeSnackbar(id)}
        iconMapping={{
          success: <AlertSuccessIcon fontSize="inherit" />,
          error: <AlertErrorIcon fontSize="inherit" />,
          warning: <AlertErrorIcon fontSize="inherit" />,
        }}
        ref={ref}
        elevation={0}
        variant="filled"
        severity={variant}
        sx={{ height: 40 }}
        action={action as React.ReactNode}
      >
        {message}
        <IconButton
          onClick={() => closeSnackbar(id)}
          sx={{ position: 'absolute', right: 5, top: 3 }}
        >
          <CloseIcon />
        </IconButton>
      </StyledMuiAlert>
    );
  }
);
