import { memo } from 'react';

import Box from '@mui/material/Box';
import { styled } from '@mui/system';
import imgMainLogo from 'assets/images/mainLogo.png';
import { SnackbarCustomProvider } from 'components/Snackbar/SnackbarCustomProvider';

type AuthContainerProps = {
  children: JSX.Element | JSX.Element[];
};

export const AuthContainer = memo<AuthContainerProps>(({ children }) => {
  return (
    <SnackbarCustomProvider>
      <StyledContainer>
        <Box
          component="img"
          alt="company main logo"
          src={imgMainLogo}
          width={108}
        />
        <StyledContent>{children}</StyledContent>
      </StyledContainer>
    </SnackbarCustomProvider>
  );
});

const StyledContent = styled(Box)(({ theme }) => ({
  margin: '0 auto',
  paddingTop: 30,
  display: 'flex',
  flexDirection: 'column',
  alignItems: 'center',
  '& .MuiDivider-root::before': {
    top: 0,
  },
  '& .MuiDivider-root::after': {
    top: 0,
  },
  '& .MuiDivider-root': {
    top: 0,
    width: 450,
  },
  [theme.breakpoints.down('md')]: {
    '& .MuiDivider-root': {
      margin: '20px auto',
    },
  },
  [theme.breakpoints.only('md')]: {
    '& .MuiDivider-root': {
      margin: '20px auto',
    },
  },
  [theme.breakpoints.up('lg')]: {
    '& .MuiDivider-root': {
      margin: '32px auto',
    },
  },
}));

const StyledContainer = styled(Box)(() => ({
  height: '100vh',
  '& > img': {
    display: 'block',
    paddingTop: 40,
    margin: '0 auto',
  },
}));
