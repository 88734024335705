import Box from '@mui/material/Box';
import Typography from '@mui/material/Typography';
import mainImage from 'assets/images/mainPageImage.png';
import notFound from 'assets/images/not_found.png';
import success from 'assets/images/success_empty.png';

export enum EmptyImage {
  GENERAL = 'general',
  NOT_FOUND = 'not_found',
  SUCCESS = 'success',
}
const Image = {
  [EmptyImage.GENERAL]: mainImage,
  [EmptyImage.NOT_FOUND]: notFound,
  [EmptyImage.SUCCESS]: success,
};

type EmptyHistoryProps = {
  image?: EmptyImage;
  title: string;
};

export const EmptyHistory: React.FC<EmptyHistoryProps> = ({
  image = EmptyImage.GENERAL,
  title,
}) => {
  return (
    <Box
      sx={{
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'center',
        justifyContent: 'center',
        gap: '25px',
        padding: '20px 30px',
      }}
    >
      <Typography textAlign="center" variant="h4">
        {title}
      </Typography>
      <Box
        width={240}
        component="img"
        alt="greeting image"
        src={Image[image]}
      />
    </Box>
  );
};
