import TableCell, { tableCellClasses } from '@mui/material/TableCell';
import { styled } from '@mui/system';

export const StyledCell = styled(TableCell)(({ theme }) => ({
  padding: '0 25px 0 0',
  borderBottom: `0.5px solid ${theme.palette.action.disabled}`,
  '&:first-of-type': {
    paddingLeft: '25px',
  },
  [`&.${tableCellClasses.head}`]: {
    padding: '0 25px 0 0',
    fontWeight: 600,
    backgroundColor: theme.palette.primary.light,
    borderBottom: 'none',
    '&:first-of-type': {
      paddingLeft: '30px',
    },

    '&:first-of-type > div:first-of-type': {
      position: 'absolute',
      top: 0,
      left: 0,
      width: 30,
      height: 30,
      backgroundColor: theme.palette.background.default,
      '& > div': {
        height: '100%',
        width: '100%',
        backgroundColor: theme.palette.primary.light,
        borderTopLeftRadius: '30px',
      },
    },
    '&:last-of-type > div:first-of-type': {
      position: 'absolute',
      top: 0,
      right: 0,
      width: 30,
      height: 30,
      backgroundColor: theme.palette.background.default,
      '& > div': {
        height: '100%',
        width: '100%',
        backgroundColor: theme.palette.primary.light,
        borderTopRightRadius: '30px',
      },
    },
  },
}));
